<template>
  <!-- HERO SECTION -->
<div class="relative pt-16 pb-32 overflow-hidden">
  <div aria-hidden="true" class="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
  <div class="relative">
    <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:grid-flow-col-dense lg:gap-24">
      <div class="col-span-2 px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
        <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-indigo-600 sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
          <span class="block font-thin text-indigo-600">App & software development</span>
          <span class="block  text-gray-900">We make technology work for you</span>
        </h1>
        <p class="mt-3 text-base font-thin text-gray-900 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          We build web and data products to make a better world.
        </p>
        <div class="mt-16">
          <router-link to="/contact" class="px-8 py-3 border border-transparent
            text-base font-medium rounded-md text-white bg-indigo-600
            hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
              Get started
            </router-link>
        </div>
      </div>
      <div class="mt-12 sm:mt-16 lg:mt-0">
        <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
          <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
          src="../assets/app-hero.jpg" alt="App and software development" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="relative pt-16 pb-32 overflow-hidden">
  <div aria-hidden="true" class="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
  <div class="relative">
    <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
      <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
        <div>
          <div class="mt-6">
            <h2 class="text-3xl font-thin font-extrabold tracking-tight text-gray-900 block inline">
              Product
            </h2>
            <p class="mt-2 text-3xl font-extralight text-gray-900 tracking-tight block inline">
              and system development.
            </p>
            <p class="mt-4 text-lg text-gray-500  font-light">
              We work on technology that makes the world a better place: from web apps, data dashboards and monitoring tools that aid the discovery of new insights from complex data, to mass-impact campaigns and websites that promote your causes and mobilise your key audiences.
            </p>

            <p class="mt-4 text-lg text-gray-500">
              We place users at the heart of our projects, and use agile methodologies to focus on delivering iterative, releasable products with our clients.
            </p>

          </div>
        </div>
        <div class="mt-8 mb-8 flex-1 border-t-2 border-gray-200" />
        <div class="mt-6">
            <router-link to="/contact" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700">
              Let's Talk
            </router-link>
        </div>
      </div>
      <div class="mt-12 sm:mt-16 lg:mt-0">
        <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
          <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
          src="../assets/datatool.jpg" alt="Product and system development" />
        </div>
      </div>
    </div>
  </div>
</div>

<FindOutAboutUS />
</template>

<script>

import { InboxIcon } from '@heroicons/vue/solid';
import FindOutAboutUS from '@/components/FindOutAboutUS.vue';

const standardWebsiteFeaturesIncludes = [
  'Modern beatiful website',
  'UX-led design and optimised user journeys that convert.',
  'Modern Web Interfaces - The Progressive Framework',
  'Free Custom Domain* (.co.uk or .com)',
  'Unlimited Revisions',
  'SSL Security',
  'SEO Features for Site Visibility',
  'Fully Mobile & Tablet Responsive',
  'Custom and secure business email',
  'Google Workspace (Business Starter)',
];

export default {
  components: {
    FindOutAboutUS,
  },
  setup() {
    return {
      standardWebsiteFeaturesIncludes,
    };
  },
};

</script>
